import quoteService from '@/services/quoteService';

export default {
	name: 'animate-scene',
	data: function() {
		return {
			scene_style: {},
			step2_style: {},
			truck_open: '',
			truck_style: {},
			sign_text: '?',
			current_step: 1,
		};
	},
	props: ['step', 'completed'],
	watch: {
		completed: function() {
			if (this.completed) {
				this.updateBackground(this.current_step + 1);
				this.updateSign(this.current_step + 1);
			}
		},
	},
	created: function() {
		this.truck_open = require('@/assets/truck1.min.png');
		this.current_step = parseInt(this.step);
		// this.checkProgress()
	},
	mounted: function() {
		this.loadImages();
		this.updateBackground(this.current_step);
		this.updateSign(this.current_step);
	},
	methods: {
		// checkProgress: function () {
		//   window.addEventListener('progress-update', (event) => {
		//     this.step = event.detail
		//     if (event.detail) {
		//       var currentStep = event.detail
		//       this.progress = helperService.getProgress(currentStep)
		//     }

		//     console.log('current step', (event && event.detail ? event.detail : 5))
		//     this.updateBackground(event && event.detail ? event.detail : 5)
		//     this.updateSign(event && event.detail ? event.detail : 5)
		//   })
		// },
		updateBackground: function(step) {
			const scene = document.getElementsByClassName('scene')[0];
			const image = this.getShippingBg();

			this.scene_style = {};

			if (step < 3) {
				this.truck_style = {};
			}

			switch (step) {
				case 1:
					scene.id = 'pick';
					break;
				case 2:
					scene.id = 'move';
					break;
				case 3:
					this.step2_style = {
						'background-image': `url(${image})`,
					};

					scene.id = 'when';

					this.truck_style = {
						'background-image': `url(${this.truck_open})`,
					};

					break;
				case 4:
					this.step2_style = {
						'background-image': `url(${image})`,
					};

					this.truck_style = {
						'background-image': `url(${this.truck_open})`,
					};

					scene.id = 'to';
					break;
				case 5:
				case 6:
				case 7:
					scene.id = 'quote';
					break;
				case 8:
					scene.id = 'thanks';
					break;
			}
		},
		updateSign: function(step) {
			const quote = quoteService.getQuote();

			switch (step) {
				case 1:
					this.sign_text = '?';
					break;
				case 2:
					this.sign_text = quote.origin_state;
					break;
				case 3:
					this.sign_text = quote.origin_state;
					break;
				case 4:
					this.sign_text = '?';
					break;
				case 5:
					this.sign_text = quote.destination_state;
					break;
			}
		},
		getSize: function() {
			const quote = quoteService.getQuote();

			if (quote && quote.size_type && quote.size_type.length) {
				return quote.size_type;
			}

			return null;
		},
		getShippingBg: function() {
			const size = this.getSize() || 'house';
			let image = '';

			switch (size) {
				case 'apartment':
					image = require('@/assets/when-apt.png');
					break;
				case 'house':
					image = require('@/assets/when.png');
					break;
				case 'office':
					image = require('@/assets/when-office.png');
					break;
			}

			return image;
		},
		checkLoadedContent: function(data) {
			for (let i = 0; i < data.length; i++) {
				const imgEle = document.getElementById(data[i].imgId);
				const actualEle = document.getElementsByClassName(
					data[i].actualClass
				)[0];
				const blurEle = document.getElementsByClassName(
					data[i].bluryClass
				)[0];
				const url = imgEle.src;
				const img = new Image();

				img.onload = function() {
					blurEle.style.display = 'none';
					actualEle.style.display = 'block';
				};
				img.src = url;

				if (img.complete) {
					img.onload();
				}
			}
		},
		loadImages: function() {
			const resources = [
				{
					imgId: 'scenery',
					actualClass: 'mountains',
					bluryClass: 'light-mountains',
				},
				{
					imgId: 'truck',
					actualClass: 'truck',
					bluryClass: 'light-truck',
				},
			];

			this.checkLoadedContent(resources);
		},
	},
};
